import React from 'react'
import { withSnackbar } from 'notistack'
import * as queryString from 'query-string'

import { makeStyles } from '@material-ui/core/styles'
import { Grid, Typography } from '@material-ui/core'

import { FONTS, URLs } from '../utils/core'
import Colors from '../utils/colors'
import Page from './page'
import Stripe from '../utils/stripe'

const useStyles = makeStyles(() => ({
  container: {
    height: '100%',
    display: 'flex',
    flexFlow: 'column',
  },
  main: {
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'space-evenly',
    margin: 20,
  },
  header: {
    fontFamily: FONTS.primary,
    fontSize: 'x-large',
    textAlign: 'center',
    margin: 10,
  },
  paragraphs: {
    fontFamily: FONTS.primary,
    fontWeight: 300,
    margin: 10,
    textAlign: 'center',
  },
  email: {
    color: Colors.get('black'),
    fontWeight: 'bold',
    textDecoration: 'none',
  },
}))

function Success({ location }) {
  const classes = useStyles()
  const [name, setName] = React.useState(null)
  const [email, setEmail] = React.useState(null)

  React.useEffect(() => {
    const { confirmation } = queryString.parse(location.search)

    if (confirmation) {
      Stripe.completePurchase(confirmation).then(data => {
        setName(data.name)
        setEmail(data.email)
      })
    }
  }, [location.search])

  function content() {
    return (
      <Grid container className={classes.container}>
        {/** MAIN CONTENT */}
        <Grid className={classes.main}>
          <Grid item>
            <Typography className={classes.header}>Order Success!</Typography>
          </Grid>
          <Grid item className={classes.paragraphs}>
            <p>
              Thank you for placing your order{name ? `, ${name}` : null}! You
              just received a confirmation email of your order
              {email ? ` at ${email}` : null}.
            </p>
            <p>
              <b>
                <u>
                  Please respond to that email with a PDF copy of your resume,
                </u>
              </b>{' '}
              and I'll get started on reviewing your resume :)
            </p>
            <p>
              If you have any problems, you can email me directly at{' '}
              <a href={`mailto:${URLs.resume_email}`} className={classes.email}>
                {URLs.resume_email}
              </a>
              .
            </p>
          </Grid>
        </Grid>
      </Grid>
    )
  }

  return <Page content={content()} />
}

export default withSnackbar(Success)
